<script>
import VueHtml2pdf from 'vue-html2pdf';
import InvoiceApi from '@/api/invoice.api';
import IntegrationApi from '@/api/integration.api';
import InvoiceContent from './invoice-content.vue';

export default {
  components: { VueHtml2pdf, InvoiceContent },

  props: {
    invoiceId: String,
    accessToken: String,
  },

  data() {
    return {
      invoice: {},
      isLoading: false,
      stripeConnected: false,
    };
  },

  async created() {
    this.$store.dispatch('page/setup', {
      title: 'View invoice',
    });

    this.isLoading = true;
    this.invoice = await InvoiceApi.findByIdForPayment(this.invoiceId, this.accessToken);
    this.isLoading = false;
  },

  methods: {
    downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },

    payInvoiceRoute() {
      this.$router.push(`/invoice/pay/${this.invoiceId}/${this.accessToken}`);
    },
  }
};
</script>

<template>
  <div class="Container Container--centered Container--padded">
    <div>
      <div v-if="!isLoading" class="PortalForm--buttons">
        <button class="Button" @click="downloadPdf">
          Download PDF
        </button>
        <button v-if="invoice.stripeConnected" class="Button Button--primary" @click="payInvoiceRoute">
          Pay Invoice
        </button>
      </div>
      <div v-if="!isLoading" class="PortalForm--invoice">
        <VueHtml2pdf
          :show-layout="false"
          :paginate-elements-by-height="1400"
          :manual-pagination="false"
          :pdf-quality="2"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="html2Pdf"
          :filename="`invoice-${invoice.number}`"
        >
          <section slot="pdf-content">
            <invoice-content :invoice="invoice" />
          </section>
        </VueHtml2pdf>

        <invoice-content :invoice="invoice" />
      </div>

      <div v-else>
        <div class="InvoiceLoader">
          <img src="../../../assets/controlla-icon-gray.svg" class="Spinner--controlla" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .InvoiceLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
</style>
